<template>
    <div class="h-full w-full flex flex-col mobileL:flex-row mobileL:relative location-container">
      <div class="h-72 w-full map rounded-tl-3xl rounded-bl-3xl" id="map">
        <l-map
          id="mapContainer" class="h-full w-full z-20"
          :class="branches.length ?
          'rounded-none rounded-t-xl mobileL:rounded-3xl mobileL:rounded-tr-none mobileL:rounded-br-none vue2leaflet-map leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom':
          'vue2leaflet-map rounded-t-xl mobileL:rounded-3xl leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom'"
          :zoom="zoom"
          :center="center"
          ref="map">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker v-for="(b, i) in branches" :key="i" :lat-lng="setLatLng(b.lat, b.lng)">
            <LIcon class-name="custom-marker" :icon-url="icon" :icon-size="dynamicSize " :icon-anchor="dynamicAnchor"/>
          </l-marker>
        </l-map>
      </div>
  
      <div class="h-48 mobileL:h-full w-full mobileL:w-4/12 xl:w-3/12 overflow-y-auto regions-container hide-scroll rounded-b-lg mobileL:rounded-none mobileL:absolute right-0 mobileL:right-2.5 top-0 py-0 mobileL:py-1 xl:py-2" ref="regionsContainer">
        <div :style="getRegionStyle(region.id)" @mouseenter="setHover(region.id)" @mouseleave="setHover(null)"
          class="w-full mobileL:w-10/12 mobileL:hover:w-11/12 xl:hover:w-full rounded-none mobileL:rounded-r-3xl mobileL:rounded-br-3xl region-wrapper bg-white-1 text-xss flex justify-center items-center mobileL:items-start py-0 mobileL:py-5 mobileL:text-base xl:text-lg font-century"
          v-for="(region, index) in regions"
          :key="index"
          @click="getRegionBranches(region.id)"
          :class="activeRegion === region.id ? 'active-region':''">
          <h4 class="font-bold text-black-1 text-center">{{ region.name }}</h4>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
  import L from 'leaflet';
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    name: 'Map',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon
    },
    props: ['businessUnitId', 'branchToSearch'], 
    data() {
      return {
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        zoom: 7,
        center: { lat: 14.5593, lng: 121.0164 },
        branches: [],
        icon: require("../assets/images/home-page/header/logo1.png"),
        activeRegion: null,
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }
    },
    created() {
      this.fetchBranches({ business_unit_id: this.businessUnitId });
      this.getRegions();
    },

    methods: {
      ...mapActions('location', ['fetchBranches', 'getRegions','setSentRegion']),
      setLatLng(lat, lng) {
        return L.latLng(lat, lng);
      },
      centerMap(lat, lng) {
        this.center.lat = lat;
        this.center.lng = lng;
        this.zoom = 10;
      },
      getRegionBranches(regionId) {
        this.activeRegion = regionId;
        this.setSentRegion(regionId);
        this.search = '';
        this.branches = this.allBranch.filter(branch => branch.region_id === regionId && branch.is_active === 1 && branch.is_displayed === 1);
        const firstBranch = this.branches[0];
        this.centerMap(firstBranch.lat, firstBranch.lng);
        this.$emit('branchesLength', this.branches.length);
      },
        
      getRegionStyle(regionId) {
        let backgroundColor = '#fef6ef';

        if (this.hoveredRegion === regionId || this.activeRegion === regionId) {
          if (this.businessUnitId === this.$layBareId) {
            backgroundColor = '#5BA311'; 
          } else if (this.businessUnitId === this.$layBarePlusId) {
            backgroundColor = '#588C7C'; 
          } else if (this.businessUnitId === this.$passionailsId) {
            backgroundColor = '#A074A4';
          } else if (this.businessUnitId === this.$lavishLashesId) {
            backgroundColor = '#E0646C';
          }
        }
        return {
          background: backgroundColor,
        };
      },
      getButtonStyle() {
        let backgroundColor = '#5BA311';

        if (this.businessUnitId === this.$layBarePlusId) {
          backgroundColor = '#588C7C'; 
        } else if (this.businessUnitId === this.$passionailsId) {
          backgroundColor = '#A074A4'; 
        } else if (this.businessUnitId === this.$lavishLashesId) {
          backgroundColor = '#E0646C';
        }

        return {
          background: backgroundColor,
        };
      },
    },
    computed: {
      ...mapGetters('location', ['allBranch', 'regions']),

      dynamicSize () {
        return [this.iconSize, this.iconSize * 1.15];
      },

      dynamicAnchor () {
        return [this.iconSize / 2, this.iconSize * 1.15];
      }
    },
    watch: {
      allBranch: {
        immediate: true,
        deep: true,
        handler(branches) {
          if (branches.length) {
            this.branches.forEach(branch => {
              branch.branch_schedule = branch.branch_schedule || {
                mon_starts: 'Close', mon_ends: 'Close',
                tue_starts: 'Close', tue_ends: 'Close',
                wed_starts: 'Close', wed_ends: 'Close',
                thu_starts: 'Close', thu_ends: 'Close',
                fri_starts: 'Close', fri_ends: 'Close',
                sat_starts: 'Close', sat_ends: 'Close',
                sun_starts: 'Close', sun_ends: 'Close'
              };
            });
          }
        }
      },
      branchToSearch: function(branchToSearch) {
        if (!branchToSearch) {
          this.branches = [];
          this.$emit('toggleMap', false);
          return;
        }
        this.activeRegion = null;
        this.search = branchToSearch.toLowerCase();
        this.branches = this.allBranch.filter((branch) =>
        branch.name.toLowerCase().includes(this.search) && branch.is_active === 1 && branch.is_displayed === 1);
        this.filteredBranch = this.branches;
        this.$emit('toggleMap', this.filteredBranch.length > 0);
      },
      '$route': {
      immediate: true,
      handler: function (route) {
        if (route.path === '/laybare/branches') {
          this.icon = require("../assets/images/home-page/header/logo1.png");
          return;
        }

        if (route.path === '/laybareplus/branches') {
          this.icon = require("../assets/images/home-page/lb-plus-logo-2.png");
          return;
        }

        if (route.path === '/passionails/branches') {
          this.icon = require("../assets/images/home-page/passionails-logo.png");
          return;
        }

        if (route.path === '/lavish-lashes/branches') {
          this.icon = require("../assets/images/home-page/lavish-logo-colored.png");
          return;
        }
      }
    }
    }
  }
  </script>
  
  <style scoped>
  .hide-scroll::-webkit-scrollbar {
    width: 0 !important;
  }
  .region-wrapper {
    cursor: pointer;
    background: #fef6ef;
  }
  .region-wrapper:hover, .active-region {
    transition: background 0.3s ease;
    width: 91.666667%;
  }
  .active-region h4 {
    color: #fefefe !important;
  }
  #mapContainer >>> .custom-marker {
    filter: drop-shadow(4px 4px 4px #00000080) !important;
  }
  @media (max-width:480px) {
    .region-wrapper:hover, .active-region {
      transition: background 0.3s ease;
      width: 100%;
    }
    .map {
      width: 100%;
    }
    .regions-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  @media (min-width: 1280px) {
    .region-wrapper:hover, .active-region {
      width: 100%;
    }
  }
  </style>
  