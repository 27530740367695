<template> 
    <div class="h-full w-full branch-details">
      <div class="h-full w-full bg-white-1 py-2 px-14 flex flex-col justify-between rounded-xl">
        <ul class="overflow-y-auto hide-scroll">
          <center class="mb-8">
            <h4 class="font-bold text-2xl xl:text-4xl text-black-1 tracking-wide">Locations</h4>
            <div class="h-0.5 xl:h-3 w-7/12 bg-pink-3 rounded-full"></div>
          </center>

          <li v-for="(branch, i) in branches" :key="i" class="mb-5 text-violet-1">
            <h3 class="text-base xl:text-2xl font-bold mb-5">{{ branch.name }}</h3>
            <div class="font-semibold text-base xl:text-xl mb-5">
              {{ branch.address }} 
              <button
              class="gray-text font-bold text-sm xl:text-tiny"
              v-if="!branch.isScheduleVisible"
              @click="showBranchSchedule(branch.id)">
              Learn More 
              <i class="fas fa-chevron-right" /></button>
              <button
                class="gray-text font-bold text-sm xl:text-tiny"
                v-else
                @click="showBranchSchedule(branch.id)">Less
                <i
                class="fas fa-chevron-right" /></button>
            </div>


            <ul class="list-disc mb-5 list-inside text-tiny xl:text-base" v-if="branch.isScheduleVisible">
              <li>MON: {{ branch.branch_schedule.mon_starts }} - {{ branch.branch_schedule.mon_ends }}</li>
              <li>TUE: {{ branch.branch_schedule.tue_starts }} - {{ branch.branch_schedule.tue_ends }}</li>
              <li>WED: {{ branch.branch_schedule.wed_starts }} - {{ branch.branch_schedule.wed_ends }}</li>
              <li>THU: {{ branch.branch_schedule.thu_starts }} - {{ branch.branch_schedule.thu_ends }}</li>
              <li>FRI: {{ branch.branch_schedule.fri_starts }} - {{ branch.branch_schedule.fri_ends }}</li>
              <li>SAT: {{ branch.branch_schedule.sat_starts }} - {{ branch.branch_schedule.sat_ends }}</li>
              <li>SUN: {{ branch.branch_schedule.sun_starts }} - {{ branch.branch_schedule.sun_ends }}</li>
            </ul>

              <h4>{{ branch.phone }}</h4>
            <button :style="getButtonStyle()" class="text-white-1 rounded-full px-5 py-1 mt-5" @click="showQRCode">Book now!</button>
          </li>
        </ul>

        <ul class="flex justify-center gap-5 mt-5">
          <li>
            <a href="#"
                :class="{'bg-grey-lightest text-grey cursor-not-allowed': currentPage === 1}"
                @click.prevent="getPreviousPage"><i class="fas fa-chevron-left" /></a>
          </li>
          <li v-for="(count, index) in range" :key="index">
            <a v-if="count != '...'" href="#" @click.prevent="handlePageChange(count)" :class="currentPage === count ? 'text-violet-1 font-semibold':''"> {{count}} </a>
            <a v-else href="#"> {{count}} </a>
          </li>
          <li>
            <a href="#"
                :class="{'bg-grey-lightest text-grey cursor-not-allowed': currentPage >= pageCount}"
                @click.prevent="getNextPage"><i class="fas fa-chevron-right" /></a>
          </li>
        </ul>
      </div>

      <div class="modal-image hide-scroll rounded-3xl" :style="isImageShow ? 'display: flex; align-items:center;' : ''" @click="isImageShow = !isImageShow">
        <img class="modal-content" :src="imgSrc" alt="QR Code">
      </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'MapLocation',

  props: ['businessUnitId', 'branchToSearch'],

  data() {
    return {
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
      markerLatLng: [],
      branches: [],
      currentPage: 1,
      rowsPerPage: 10,
      pageCount: 0,
      activeRegion: null,
      branchesByRegion: [],
      range: [],
      isImageShow: false,
      imgSrc: '',
      filteredBranch: [],
      search: '',
    }
  },

  created() {
    this.fetchBranches({ business_unit_id: this.businessUnitId });
    this.getRegions();
  },

  methods: {
    ...mapActions('location', ['fetchBranches', 'getRegions']),

    getRegionBranches(regionId) {
      this.activeRegion = regionId;
      this.branchesByRegion = this.allBranch.filter(branch => branch.region_id === regionId && branch.is_active === 1 && branch.is_displayed === 1);

      for (let branch of this.branches) {
        const { branch_schedule } = branch;
        branch.isScheduleVisible = false;

        if (branch_schedule) {
          branch_schedule.mon_starts = branch_schedule.mon_starts ? this.convertTime(branch_schedule.mon_starts) : 'Close';
          branch_schedule.mon_ends = branch_schedule.mon_ends ? this.convertTime(branch_schedule.mon_ends) : 'Close';
          branch_schedule.tue_starts = branch_schedule.tue_starts ? this.convertTime(branch_schedule.tue_starts) : 'Close';
          branch_schedule.tue_ends = branch_schedule.tue_ends ? this.convertTime(branch_schedule.tue_ends) : 'Close';
          branch_schedule.wed_starts = branch_schedule.wed_starts ? this.convertTime(branch_schedule.wed_starts) : 'Close';
          branch_schedule.wed_ends = branch_schedule.wed_ends ? this.convertTime(branch_schedule.wed_ends) : 'Close';
          branch_schedule.thu_starts = branch_schedule.thu_starts ? this.convertTime(branch_schedule.thu_starts) : 'Close';
          branch_schedule.thu_ends = branch_schedule.thu_ends ? this.convertTime(branch_schedule.thu_ends) : 'Close';
          branch_schedule.fri_starts = branch_schedule.fri_starts ? this.convertTime(branch_schedule.fri_starts) : 'Close';
          branch_schedule.fri_ends = branch_schedule.fri_ends ? this.convertTime(branch_schedule.fri_ends) : 'Close';
          branch_schedule.sat_starts = branch_schedule.sat_starts ? this.convertTime(branch_schedule.sat_starts) : 'Close';
          branch_schedule.sat_ends = branch_schedule.sat_ends ? this.convertTime(branch_schedule.sat_ends) : 'Close';
          branch_schedule.sun_starts = branch_schedule.sun_starts ? this.convertTime(branch_schedule.sun_starts) : 'Close';
          branch_schedule.sun_ends = branch_schedule.sun_ends ? this.convertTime(branch_schedule.sun_ends) : 'Close';
        }
      }

      this.search = '';
      this.currentPage = 1;
      this.pageCount = Math.ceil(this.branchesByRegion.length / this.rowsPerPage);
      const start = this.currentPage * this.rowsPerPage - this.rowsPerPage;
      const end = start + this.rowsPerPage - 1;
      this.branches = this.branchesByRegion.filter((item, index) => index >= start && index <= end);
      this.organisePageLinks();
    },

    convertTime(time) {
      if (time === 'Close') return;

      let formatted;
      let dateNow = new Date().toLocaleDateString()
      let dateTime = `${dateNow} ${time}`
      let convert = new Date(dateTime).getTime()
      formatted = format(convert, 'h:mm a')

      return formatted;
    },

    handlePageChange(page) {
      this.currentPage = page;
      const start = page * this.rowsPerPage - this.rowsPerPage;
      const end = start + this.rowsPerPage - 1;

      if (!this.search) {
        this.branches = this.branchesByRegion.filter((branch, index) => index >= start && index <= end);
      } else {
        this.branches = this.filteredBranch.filter((branch, index) => index >= start && index <= end);
      }
      
    },

    organisePageLinks() {
        this.range = [];
        const firstPage = 1;
        const maxPage = 3;
        const previousPage = this.currentPage - 1;
        const nextPage = this.currentPage + 1;
        for (let i = 1; i <= this.pageCount; i++) {
            if (
                i == firstPage || 
                i == this.pageCount || 
                i == this.currentPage || 
                i == previousPage || 
                i == nextPage || 
                (i <= maxPage && this.currentPage < maxPage) || 
                (i >= this.pageCount - maxPage && this.currentPage > this.pageCount - maxPage)
            ) {
                let index = this.range.length
                if (index > 0 && this.range[index - 1] < i - 1) {
                  
                    this.range.push('...')
                }
                this.range.push(i)
            }
        }
    },

    getPreviousPage() {
      const firstPage = 1;
      if (this.currentPage === firstPage) return;

      this.handlePageChange(this.currentPage - firstPage);
    },

    getNextPage() {
      const addPageNumber = 1;
      if (this.currentPage >= this.pageCount) return;

      this.handlePageChange(this.currentPage + addPageNumber)
    },

    showQRCode() {
      this.isImageShow = !this.isImageShow;

      if (this.businessUnitId === this.$layBareId) {
        this.imgSrc = require('../assets/images/location-page/lb-qr.png');
      } else if (this.businessUnitId === this.$layBarePlusId) {
        this.imgSrc = require('../assets/images/location-page/lb-plus-qr.png');
      } else if (this.businessUnitId === this.$passionailsId) {
        this.imgSrc = require('../assets/images/location-page/pn-qr.png');
      } else if (this.businessUnitId === this.$lavishLashesId) {
        this.imgSrc = require('../assets/images/location-page/pn-qr.png');
      }
    },

    showBranchSchedule(id) {

      let branch = this.branches.find(branch => branch.id === id);
      branch.isScheduleVisible = !branch.isScheduleVisible;
      const index = this.branches.indexOf(branch);
      this.$set(this.branches, index, branch);
    },

    getRegionStyle(regionId) {
      let backgroundColor = '#fef6ef';

      if (this.hoveredRegion === regionId || this.activeRegion === regionId) {
        if (this.businessUnitId === this.$layBareId) {
          backgroundColor = '#5BA311'; 
        } else if (this.businessUnitId === this.$layBarePlusId) {
          backgroundColor = '#588C7C'; 
        } else if (this.businessUnitId === this.$passionailsId) {
          backgroundColor = '#A074A4';
        } else if (this.businessUnitId === this.$lavishLashesId) {
          backgroundColor = '#E0646C';
        }
      }
      return {
        background: backgroundColor,
      };
    },
    getButtonStyle() {
      let backgroundColor = '#5BA311';

      if (this.businessUnitId === this.$layBarePlusId) {
        backgroundColor = '#588C7C'; 
      } else if (this.businessUnitId === this.$passionailsId) {
        backgroundColor = '#A074A4'; 
      } else if (this.businessUnitId === this.$lavishLashesId) {
        backgroundColor = '#E0646C';
      }

      return {
        background: backgroundColor,
      };
    },
  },

  computed: {
    ...mapGetters('location', ['allBranch', 'branchesByName', 'error', 'regions','sentRegionID']),

    propsToWatch() {
      return this.pageCount, this.currentPage, Date.now()
    },

    regionIdFromStore() {
      const receivedData = this.sentRegionID;
      return receivedData;
    }
  },

  watch: {
    propsToWatch: {
      handler: 'organisePageLinks',
      immediate: true
    },

    regionIdFromStore: {
    handler: function(newRegionId, oldRegionId) {
      if (newRegionId !== oldRegionId) {
        this.getRegionBranches(newRegionId);
      }
    },
    immediate: true
  },

    allBranch: {
      immediate: true,
      deep: true,
      handler: function(branches) {
        if (!branches.length) return;

        let schedule = {
          mon_starts: 'Close',
          mon_ends: 'Close',
          tue_starts: 'Close',
          tue_ends: 'Close',
          wed_starts: 'Close',
          wed_ends: 'Close',
          thu_starts: 'Close',
          thu_ends: 'Close',
          fri_starts: 'Close',
          fri_ends: 'Close',
          sat_starts: 'Close',
          sat_ends: 'Close',
          sun_starts: 'Close',
          sun_ends: 'Close'
        }

        for (let branch of branches) {
          if (!branch.branch_schedule) {
            branch.branch_schedule = schedule;
          }
        }
      }
    },

    branchToSearch: function(branchToSearch) {
      if (!branchToSearch) {
        this.branches = [];
        return;
      }

      this.activeRegion = null;
      this.search = branchToSearch;
      this.currentPage = 1;
      this.branches = this.allBranch.filter((branch) =>
      branch.name.toLowerCase().includes(branchToSearch.toLowerCase()) && branch.is_active === 1 && branch.is_displayed === 1);
      this.filteredBranch = this.branches;
      this.pageCount = Math.ceil(this.branches.length / this.rowsPerPage);
      this.organisePageLinks();
    },

    '$route': {
      immediate: true,
      handler: function (route) {
        if (route.path === '/laybare/branches') {
          this.icon = require("../assets/images/home-page/header/logo1.png");
          return;
        }

        if (route.path === '/laybareplus/branches') {
          this.icon = require("../assets/images/home-page/lb-plus-logo-2.png");
          return;
        }

        if (route.path === '/passionails/branches') {
          this.icon = require("../assets/images/home-page/passionails-logo.png");
          return;
        }

        if (route.path === '/lavish-lashes/branches') {
          this.icon = require("../assets/images/home-page/lavish-logo-colored.png");
          return;
        }
      }
    }
  }
}
</script>

<style scoped>
.hide-scroll::-webkit-scrollbar {
  width: 0 !important
}

.region-wrapper {
  cursor: pointer;
  background: #fef6ef;
}

.region-wrapper:hover, .active-region {
  transition: background 0.3s ease;
  width: 91.666667%;
}

.active-region h4 {
  color: #fefefe !important;
}

#mapContainer >>> .custom-marker {
  filter: drop-shadow(4px 4px 4px #00000080) !important;
}

.gray-text {
  color: #1E1E1E80;
}

.modal-image {
  display: none;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ffffffa6;
}

.modal-content {
  margin: auto;
  display: block;
  width: 100%;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@media (max-width:480px){

  .region-wrapper:hover, .active-region {
    transition: background 0.3s ease;
    width: 100%;
  }

  .regions-container{
    display:grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

}

@media (min-width: 1280px) {
  .region-wrapper:hover, .active-region {
    width: 100%;
  }
}
</style>

